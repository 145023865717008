module.exports = {
	'WEB_URL': 'https://po-dev-ui.thedemo.is',
	'API_URL': 'https://po-dev-cms.thedemo.is',
	'STATIC_ASSETS_CDN': 'https://d2tbelhx7wnhve.cloudfront.net',
	'CLIENT_EMAIL': 'read-google-sheets@quick-platform-357711.iam.gserviceaccount.com',
	'PRIVATE_KEY': '-----BEGIN PRIVATE KEY-----\\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQCuqZMotXlKDwFi\\ne9FOWZHUD7WPKagRzD5MdV4ZJLEYwZhvsjJf48I2oCQYL/R7lO4MOijKvmH2Gfpb\\n4FQUyePhyT8futwT6dg4CzafiCF6n3dD4Sj3N1ZYlBsFiOOwKAcY8XWRLVKQzbOH\\n8AaABcZVJgJS0dNjVLTgKXUABfBn1zslTcRNNio7BBDUPA+5wGcXQhNrA0DNTUnX\\n6RNn8XVlWaNxocYU/mZL9Pbxyrn1Gfw0OrPzDigoHIdJHd4awCrKNHxyvAc4c5wL\\nBqIwKwuSmAng7qnpWDhT4aGTfWRNEx3tWPNGWfA+jYkRb9fmX2i2QWFZupr21cqv\\ntvUh3exlAgMBAAECggEADKwN8AwBPEUhbxaXpXfB06ETevW6lWv8Rcoqz70wl3YN\\noWZMLwEpyT0je+5tblKXAM5uwOLriDzzQ/DfI3ulq8R7mXQ9B7mAGp9++40XYhD+\\nyK8l1zstvxMjxhTb8XRoq2pyrVvx8Rfo2MnVLDr/BuomjKwKNQXjcki1DebgaHrU\\nzFNiE7wmw37qx8U6QWCeXngrO1el0VwUYB920LB/Qb59Z2IQEPWwxvoGIoYjqrXC\\n+nq2csuleesTuW+fcGLlAYCe2dx2JUrGRUdVhONT6o1OSq82YfXK7urKzfzhnGA1\\noOhrK4xzGB3MFx7kq1fi4887hOLtoEN7Id06RmiUMQKBgQDpLQNBESDUpnZ6fnqy\\n00isaJxYwBJ8Ol/lncqEZzXsWWWVJAezYGUp62R/Ge4DRkS6EwqSELa9RJvQJwsN\\nulhTefiQwYyPtDi6c7wkiU/R5pkQQheveHFqba7TTZCOXdI4Ag2taa6f2gqseQVb\\nN623zjHxDbB7jUrMKnjl9tWY2QKBgQC/wlDTfFses1JkrzgNOrvnfYHllifh468M\\n1XlmHwAvJd0vElNrejRM00WmygBmM0h+GnTqsqANCstLQUzHh0TjBSpXQaFR7np9\\nX7/a2IOVmeA5EkW7gHX6vJFq4zDLWaRXHH4DSmgRuChKsYrLGLYA7dUhV+Mdb9GK\\nBOeMhKOYbQKBgAVLUFeZ2/T4EpiVZpPI2E2R0nrxYXoQmTzNaFu5PDAxl2zbdJFO\\nDv30qkN4gz7vyX+wDv1iA8QoWqFPQaD+KiM7Ebm3q0lX/jG9jvgFZhqN8cu8tGz3\\nfxu7gcyqZi3VU4CSC110mNJoUPW5ibWYfgk+VUPz253yh1A7ZqNSB2eBAoGBALZ+\\nFTHPrWAgRI9xeaSNevoppY97QWVmq9dI6gnYAvLb1A3SIPWuDUv33NeIcqzQrXLl\\ndtNNP6hp12M9N/bDszliVxNHcGFq1cOvdgSt/TxIfu2RiC+TZ7RB9RqGMI+2NgJ5\\nKSu0iioi2qWLJY13bO6haS4cWBZRrlbtp/dA2/hZAoGBAOj3epFG1yR+9qsRkPRs\\nvsAhXH0AHAdGs8KwHl4x/Yxd6BObp0ftnFfYNhoTx58sJLlFCusOqTDuSsLT0bt8\\nxMh250HFib8uUwzOgKvdQg6bTwE8phXm3iSQxpdrxNRZCyUVQkUtclzAGPzrkdsO\\nDP4+rAANWnj+R8C2JXimXTsX\\n-----END PRIVATE KEY-----\\n',
	'SUPPLIER_SPREADSHEET_ID': '1q3GKmcBWyBd5k21kfdoWuGZ-DWDC-xFeERpp-hnQV0o',
	'SUPPLIER_SHEET_ID': '0',
	/*2022
	'CLIENT_SPREADSHEET_ID': '1Tz3WP8Pex1GLQAKNvBM0glS1KH-jTw2hoge4g0XmpiA',
	'CLIENT_SHEET_ID': '0',
	2023*/
	'CLIENT_SPREADSHEET_ID': '1tuPWOfn8MUaWAx6mqhlqc7NtdmT6_ro_7NYwJmm3Ryw',
	'CLIENT_SHEET_ID': '1394205894',
	//'EXPORT_SPREADSHEET_ID': '1tT7-45eatxUDA6w4HLiwScyIWCc5AZmvZHfjHDt4nBg'
	'EXPORT_SPREADSHEET_ID': '0',
	'EXPORT_SPREADSHEET_ID_STAGING': '1baAM8WTLqIqRpvU3nyDH21h-nMJX7HbBk71HsbVC6ls',
	'CLIENT_EMAIL_WRITE_STAGING': 'writepostosheet@portal-staging-list-sheets.iam.gserviceaccount.com',
	'PRIVATE_KEY_WRITE_STAGING': '-----BEGIN PRIVATE KEY-----\\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQCVBdKxQ9w7qZmy\\ni/i6Yk4hK/iwPSaQcnJnWd1/2ROITRBbNZXT8VIJyPlMXqHEcKZHxxZtDK1aAul4\\niH+M11+u3qC1hdwHR6H8x6wLSzvZEroYHXzD9cG+q3YA5zpMHVeQVIr8UmKfzFxF\\ngH9sMosDbMN1Anx4xcgw30IkA5KQXPcv8j/0QWu6BqCGC/qsmk/IngU87gLCMnGB\\nt/RPd8GvmfCL1T2ZJIOkMpwzJC7ZxRycZv+dlkW7oAIyGiMEfOF+T2c1Hs69hp3Y\\nUsMwZocGtdvExl7Smr8klC0Qb63QIIDOeHEkRb+A5TeeOjVqDrFF1u4KQ4bWGW6w\\nVJf7s0cPAgMBAAECggEAJK4JPQ6J77mAFj1znFTEDFApZx+2FUnGNQAej/BbaWom\\ng3y+Ayvb2IK6Mfz87kAX/YTYVUEQjh43Xy6hzoXYjE9J2UQ4cyZDjdeL1j/PXE/K\\ntFLVudoMtSS45+ICLGfwfJsClrakhdakQ4ssUfJi2xXzSiKxhnyQGM9rFTg35AZN\\njeKO9oHKnmmemsFJE8DnSqk6nPV+zvwL0oWyHItrWwPvizvACqvLTiHGyERti5aF\\nOXV5c9u7jfVzLpRBGEQ3kytkkTw21Utwp3SB1Ekjieks/cs+fQ+Z1SoHLOJcc41u\\nqZ1DtW7h0YrMMMMMaC44LibUI+mCL9tFGl2Q4rrxKQKBgQDP/XsaEkSBmu2YvCeL\\nVCX3GteC00WED4pnkzFLnQ7zLdri5FG5kzkxBzTpvzV51qFvjBDO6D7bPC6M3SC5\\nIs6hBcTwxtXAQEu5zU4WXIBGPr8aVgqrbPoE0b2OFgWV11g64dhChmaT2i8wFClZ\\n1UiceHfw+YC/ncGlmaqqf9N+awKBgQC3a9mLd0wiykzcnbpJZkshkalJzR05EXt6\\njXTby3Gl20/0OlKx7bRBA7d1IVdfhR04Ss6IzD0xLhfajP0Zwt82qDL9sn+Vxf1t\\nRezlsYZ+8YAYwAgkc1KBh0JntEKelUNTgiOEgqEdVGYs76mGazM61j4Fk3DGqKn6\\nKiAv+Yk67QKBgQC22UIy3mJpillcz/T30Sd7QJa8BZ50hfEIM2e/8Lhm4yYuF8kd\\nGyfJSWukHxwVG7JazUktjRFNTXAjGHefKtF60AaeIj9iP2QTWUFP+Uwv9ROuRHwm\\nzqxSNk2trwCEBYgkY83qVhux2btoSiE8P3wRSvsu1znvuvG18w0NzUiSNwKBgBYS\\nJjU//6tYCHOrjxTx+Cxr6KD2y4NodSFEEEG6c0xv3WkX84dxgPUpy1OO1YKq898o\\nMT4IxXw7fadQu8vzxQRArRIV/oA5OMuDVIQ3HmX8DVvtD43DSrRHYFg2OqX2dAmC\\naV+ykEyFMV5K4G1KTPnRedkV6sYc2Yc+tmzPbBHpAoGBAJ/t7z64XE6ARdGKIwSb\\n41cXriyQlgg8/I7nMZuqgkHTdrOtPIczkOp0KUsM+KkT4437bblbgddvMUj+sbO4\\n81jdXEEe3Nvyj1pLNVeCxYK40QBBu0Mbi6Oy9lYKJak+5sEUIC8W4gn6Fy1+eruS\\nkO2Dx2az4VZyYExd2rZ5EzDX\\n-----END PRIVATE KEY-----\\n',
	'PUBLIC_VAPID_KEY': 'BIcCHHUBRdp1Ds9QdkW0-bIqIUijTlB8i5KDUjg4VgONXsn2sT466assAeba6XnLmyl9nptoo9dA3TvtmiMnADc',
	'ENVIRONMENT': 'staging',
};